import * as React from "react";

export const BASE_URL = process.env.GATSBY_NODEBB_URL;

export const createUrl = (base: string, path: string) => `${base}${path}`;

export const getTopics = (page, rName, sortBy): [string, { method: string }] => [
  createUrl(BASE_URL, `${rName}?lang=en-GB&page=${page ? page : 1}${sortBy}`),
  {
    method: "GET",
  }
];

export const getTopicsByTagName = (page, tagName): [string, { method: string }] => [
  createUrl(BASE_URL, `tags/${tagName}?lang=en-GB&page=${page ? page : 1}`),
  {
    method: "GET",
  }
];

export const getThread = (thread, page): [string, {method: string}] => [
  createUrl(BASE_URL, `topic${thread}?lang=en-GB&page=${page}`),
  {
    method: "GET",
  }
];

export const getCategories = (page): [string, { method: string }] => [
  createUrl(BASE_URL, `?lang=en-GB&page=${page}`),
  {
    method: "GET",
  }
];


export const getSearch = (searchTerm,page): [string, { method: string }] => [
  createUrl(BASE_URL, `search?${searchTerm}&page=${page}`),
  {
    method: "GET"
  }
];
