import React, { useState, useEffect } from "react";
import { upDownVote, hasVotedOnPost } from "../../../lib/helpers";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// @ts-ignore
import LikesSVG from "../icons/Likes.svg";

import UserLikesSVG from "../icons/UserLiked.svg";

const useStyles = makeStyles((theme) => ({
  likesText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    textTransform: "uppercase",
    color: "#6464FF",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  likesTopicText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    textTransform: "uppercase",
    color: "#6464FF",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: "8px",
  },
  iconVoted: {
    marginRight: "8px",
    backgroundColor: "RED"
  },

}));

function Likes(props) {

  const { likes, pID, userID, slug, creatorUserID, isAuth  } = props;
  const url = typeof window !== "undefined" ? window.location.href : "";
  const classes = useStyles();
  const [getLikes, setLikes] = useState(likes);
  const [votedOn, setVotedOn] = useState(false);

  const [getTotalLikes, setTotalLikes] = useState(likes);

  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + process.env.NODEBB_MASTER_API_TOKEN);
  myHeaders.append("Content-Type", "application/json");

  var ownPost = false;

  if (creatorUserID == userID) {
    ownPost = true;
  }


 useEffect(() => {
   (async () => {

     if (userID != creatorUserID) {
       var voted = hasVotedOnPost(pID);

       setVotedOn(voted);

     }
    
    })();
 }, []);

  useEffect(() => {
    (async () => {

      if (url.includes("/topic")) {
        await getPostTotalLikes();

      }

    })();
  }, []);

  var getLiveLikes = async function () {
    var likes = await getTheLikes();

    setLikes(likes.votes);
  }

  var getTheLikes = async function () {
   
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    var url = process.env.GATSBY_NODEBB_URL + "v3/posts/" + pID + "?_uid=" + 1;


    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then(result => { console.log(result); return result.response; });
  }

  var getPostTotalLikes = async function () {
    var likes = await getThePostTotalLikes();

    var posts = likes && likes.posts;

    var totalLikes = 0;

    for (var i = 0; i < posts.length; i++) {
      totalLikes += posts[i].votes;
    }
    setTotalLikes(totalLikes);
    //console.log(totalLikes);

  }

  var getThePostTotalLikes = async function () {

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    var url = process.env.GATSBY_NODEBB_URL + "topic/" + slug + "?_uid=" + 1;


    return fetch(url, requestOptions)
      .then((response) => response.json())

  }

  var addLike = async function (pID, uID, delta) {

    var raw = JSON.stringify({
      "delta": delta
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    var url = process.env.GATSBY_NODEBB_URL + "v3/posts/" + pID + "/vote?_uid=" + uID;

    return fetch(url, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
   
  }

  const handleVote = async () => {
    if (isAuth) {
      if (userID != creatorUserID) {
        var voteUpOrDown = await upDownVote(pID);

        //console.log("voteUpOrDown", voteUpOrDown)

        var delta = 0;

        if (voteUpOrDown == null) {
          setVotedOn(false);
        }
        else {

          var checkTerm = pID + "-UP";

          if (voteUpOrDown.includes(checkTerm)) {
            //have previously voted UP - delta becomes 0 for downvote
            setVotedOn(true);
            delta = 1
          }
          else {
            setVotedOn(false);
          }
        }


        await addLike(pID, userID, delta);

        //braze
        try {
          window.forumPostLiked(window.location.pathname, pID, isAuth);
        } catch (e) {

        }
       

        await getLiveLikes();
      }
    }
    else {
      //braze event for not logged in
      //braze
      try {
        window.forumPostLiked(window.location.pathname, pID, isAuth);
      } catch (e) {

      }
      window.forumStartClick(isAuth)
    }
   
  }


  if ((url.includes("/topic")) || (url.includes("/search")) || (url.includes("/tags"))) {
    
    return (
      <span className={classes.likesTopicText}>

        <img src={LikesSVG} className={classes.icon} />
        {getTotalLikes} {getTotalLikes !== 1 ? "Likes" : "Like"}
      </span>
    )
  }
  else {
    return (
      <span className={ownPost ? classes.likesTopicText : classes.likesText} onClick={handleVote}>

        <img src={votedOn ? UserLikesSVG : LikesSVG} className={classes.icon} />
        {getLikes} {getLikes !== 1 ? "Likes" : "Like"}
      </span>
    )
  }

 
}

export default Likes;
