import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import DeserializationPlugin from "../plugins/DeserializationPlugin";
import * as uuid from 'uuid'

function Placeholder() {
  return <div>What’s on your mind…</div>;
}

function Teaser(props) {
  const { pID } = props;

  const [getTeaser, setTeaser] = useState("");
  const [loaded, setLoaded] = useState(false);
  const outputRef = useRef(null)

  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + process.env.NODEBB_MASTER_API_TOKEN);
  myHeaders.append("Content-Type", "application/json");

  useEffect(() => {
    (async () => {
        await getPostTeasers();
    })();
  }, []);

  useEffect(() => {
    setLoaded(true)
    return () => setLoaded(false)
  }, [])

  var getPostTeasers = async function () {
    var teaser = await getTheTeaser();
    setTeaser(teaser)
  }


  var getTheTeaser = async function () {

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    var url = process.env.GATSBY_NODEBB_URL+"v3/posts/" + pID + "?_uid=" + 1;


    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then(result => { return result.response; });
  }

  if (getTeaser.content !== "" && getTeaser.content !== undefined) {
    return (
      loaded ? <span key={uuid.v4()}>
          <LexicalComposer
            key={uuid.v4()}
            initialConfig={{
              readOnly: true,
              onError(error) {
                throw error;
              },
            }}
          >
            <DeserializationPlugin key={uuid.v4()} content={getTeaser.content} element={"output-teaser"}  outputRef={outputRef} isTeaser={true}/>
            <div id={"output-teaser"} ref={outputRef} key={uuid.v4()}>
              <RichTextPlugin
                key={uuid.v4()}
                contentEditable={<ContentEditable />}
                placeholder={<Placeholder />}
              />
            </div>
          </LexicalComposer>
        {/*{getTeaser}*/}
    </span> : <span>loading...</span>
    )
  }
  else {
    return <span>Loading...</span>
  }
}
export default Teaser;
