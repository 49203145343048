import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Container from "../../components/container";
import { Link } from "gatsby";

const useStyles = makeStyles((theme) => ({
  dotCourse: {
    color: "#3C465F",
    marginLeft: "7px",
    marginRight: "7px",
  },
  linkTextCourse: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#3C465F",
    textTransform: "uppercase",
  },
  holder: {
    paddingBottom: "24px",
  },
}));

export const getTopicPageBreadcrumbs = () => [
  {
    title: "Home",
    linkReference: "visible/members/",
  },
  {
    title: "All Topics",
    linkReference: "forum/",
  },
];

export const getConversationPageBreadcrumbs = (threadTitle, slug) => [
  {
    title: "Home",
    linkReference: "visible/members/",
  },
  {
    title: "All Topics",
    linkReference: "forum/",
  },
  {
    title: threadTitle,
    linkReference: slug,
  },
];

export const getAdviserPageBreadcrumbs = () => [
  {
    title: "Home",
    linkReference: "visible/members/"
  },
  {
    title: "Experts",
    linkReference: "visible/experts/"
  }
]

const ForumBreadcrumb = ({ pageBreadcrumb }) => {
  const classes = useStyles();

  return (
    <Typography variant="subtitle2" className={classes.holder}>
      {pageBreadcrumb &&
        pageBreadcrumb.map((bc, index) => (
          <BreadCrumbItems bc={bc} classes={classes} index={index} />
        ))}
    </Typography>
  );
};

const BreadCrumbItems = ({ bc, classes, index }) => {
  const { title, linkReference } = bc;

  let pathName =
    title === "Home" || title === "Community" || title === "Experts"
      ? linkReference
      : title === "All Topics"
      ? `${linkReference}topics`
      : `forum/topics/${linkReference}`;

  // if (title ===  "")

  // const fullLink =

  return (
    <>
      {index !== 0 && <span className={classes.dotCourse}>{" • "}</span>}
      <Link
        to={`/${pathName}`}
        style={{ textDecoration: "none" }}
        className={classes.linkTextCourse}
      >
        {title}
      </Link>
    </>
  );
};

export default ForumBreadcrumb;
